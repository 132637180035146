<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Amortización de Crédito - Nuevo</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/amortizacion-credito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Crédito:</label>
                    <v-select @input="GetCreditByID" placeholder="Seleccione un crédito" class="w-100" :filterable="false" label="text" v-model="client" @search="SearchCredit" :options="clients"></v-select>
                    <small v-if="errors.id_credit" class="form-text text-danger" >Seleccione un credito</small>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_amortization.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Seleccione un código</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select v-model="credit_amortization.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input disabled type="date" class="text-center" v-model="credit_amortization.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Cuota:">
                    <b-form-input disabled type="text" class="text-center" v-model="credit_amortization.quota_number"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Saldo Capital:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="credit_amortization.balance"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total:">
                    <b-form-input type="number" step="any" class="text-right"  @change="UpdateBalanceQuota" v-model="credit_amortization.total"></b-form-input>
                    <small v-if="errors.total"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button type="button" class="form-control" variant="primary" @click="GetCreditDetailByAmortization">Calcular Amortización</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Doc. Adjunto:">
                    <b-form-file  accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="credit_amortization.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

              

                <b-col md="12" v-if="credit_amortization.credits_details_agricultural.length > 0">
                  <div class="alert alert-dark" role="alert">
                    <strong>PAGOS DEL CAPITAL</strong> 
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="6%" class="text-center">#</th>
                          <th width="94%" class="text-center">Capital</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr v-for="(item, it) in credit_amortization.credits_details_agricultural" :key="it">
                          <td class="text-center">{{ item.quota_number }}</td>
                          <td class="text-right"> 
                            <b-form-input size="sm" @change="CalculateDiferenceCapital" :disabled="credit_amortization.quota_number == item.quota_number" type="number" step="any" class="text-right" v-model="item.capital_balance"></b-form-input>
                          </td>
                        </tr>
                        <tr v-if="parseFloat(credit_amortization.capital_diference) != 0">
                          <td></td>
                          <td class="text-right"><strong class="text-danger">{{ credit_amortization.capital_diference }}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
               
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>CRONOGRAMA DE ṔAGO</strong> 
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="9%" class="text-center">Fecha</th>
                          <th width="7%" class="text-center">Cuota</th>
                          <th width="7%" class="text-center">Amortización</th>
                          <th width="7%" class="text-center">Capital</th>
                          <th width="7%" class="text-center">Interes</th>
                          <th width="7%" class="text-center">Saldo <br> Capital </th>
                          
                      
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit_amortization.credits_details" :key="it">
                        <tr v-if="item.state == 1">
                          <td class="text-center">{{ item.quota_number }}</td>
                          <td class="text-center"> {{ item.date  }}</td>
                          <td class="text-right"> {{ item.quota  }}</td>
                          <td class="text-right"> {{ item.amortization  }}</td>
                          <td class="text-right"> {{ item.capital_balance  }}</td>
                          <td class="text-right"> {{ item.interest  }}</td>
                          <td class="text-right"> {{ item.balance  }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
            
               

               

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "CreditPaymentAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CreditAmortization',
      role: 2,
      credit_amortization: {
          id_credit_amortization:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_product:'',
          id_credit:'',
          id_credit_detail:'',
          code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          annexes:'',
          observation:'',
          quota : '0.00',
          amortization : '0.00',
          total:'0.00',
          state:1,
          quota_number : '',
          balance : '',
          interest : '',
          
          credits_details : [],
          credits_details_agricultural : [],
          capital_diference:'0.00',
      },
      file:null,
      modules:[],
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      client: null,
      clients: [],
      errors: {
        id_client: false,
        payment_method: false,
        date: false,
        total: false,
        id_credit:false,
        id_credit_detail: false,
        quota:false,
        amortization: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetCorrelative();
  },
  methods: {
    GetCorrelative,
    GetCreditByID,
    GetCreditDetailByAmortization,

    AddCreditAmortization,
    Validate,

    SearchCredit,
    onFileChange,
    UpdateBalanceQuota,
    CalculateDiferenceCapital,

    OpenVoucher,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function UpdateBalanceQuota() {
  this.credit_amortization.total = this.credit_amortization.total.length == 0 ? 0 : this.credit_amortization.total;
  this.credit_amortization.total = parseFloat(this.credit_amortization.total).toFixed(2);

  for (let index = 0; index < this.credit_amortization.credits_details_agricultural.length; index++) {
    const element = this.credit_amortization.credits_details_agricultural[index];
    if (element.quota_number == this.credit_amortization.quota_number) {
        element.capital_balance = this.credit_amortization.total;
    }
  }
  this.CalculateDiferenceCapital();
}

function CalculateDiferenceCapital() {
  var capital_balance = 0;
  if (this.credit_amortization.id_product == 2) { /// credito agropecuario
    for (let index = 0; index < this.credit_amortization.credits_details_agricultural.length; index++) {
      const element = this.credit_amortization.credits_details_agricultural[index];
      element.capital_balance = element.capital_balance.length == 0 ? 0 : element.capital_balance;
      element.capital_balance = parseFloat(element.capital_balance).toFixed(2);
      capital_balance += parseFloat(element.capital_balance);
    }
  }

  this.credit_amortization.capital_diference = parseFloat(this.credit_amortization.balance) - parseFloat(capital_balance);
  this.credit_amortization.capital_diference = parseFloat(this.credit_amortization.capital_diference).toFixed(2);
}


function onFileChange(e) {
  this.credit_amortization.annexes = e.target.files[0];
}

function GetCorrelative() {
  let me = this;
  let url = me.url_base + "data/get-correlative/CreditAmortization";
  me.requirements = [];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_amortization.code = response.data.result.number;
      }
    });
}

function GetCreditDetailByAmortization() {
  let me = this;
  let url = me.url_base + "credit-amortization/credit-detail-amortization";
  if (this.client == null) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un crédito', timer: 2000,}); 
    return false;
  }
  if (this.credit_amortization.id_credit_detail.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un crédito', timer: 2000,}); 
    return false;
  }
  if (this.credit_amortization.total.length == 0 ||  parseFloat(this.credit_amortization.total) < 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese una amortización', timer: 2000,}); 
    return false;
  }

  if (parseFloat(this.credit_amortization.total) > parseFloat(this.credit_amortization.balance)) {
    Swal.fire({ icon: 'warning', text: 'El monto de la amortización no debe superar al saldo capital', timer: 2000,}); 
    return false;
  }

  if (this.credit_amortization.id_product == 2) { // credit agropecuario
    if (this.credit_amortization.capital_diference.length == 0 ||  parseFloat(this.credit_amortization.capital_diference) != 0) {
      Swal.fire({ icon: 'warning', text: 'Verifique que los pagos al capital no tengo una diferencia al saldo capital', timer: 2000,}); 
      return false;
    }
  }
  

  let data =  this.credit_amortization;
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_amortization.credits_details = response.data.result.credits_details;
        me.credit_amortization.capital_diference = '0.00';
      }else{
        Swal.fire({ icon: 'warning', text: response.data.message, timer: 2000,}); 
    return false;
      }
    });
}

function SearchCredit(search, loading) {
  
  let me = this;
   let url = this.url_base + "credit-amortization/credits-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function AddCreditAmortization() {
  let me = this;
  let url = me.url_base + "credit-amortization/add";
  me.isLoading = true;

  let data = new FormData();
  data.append("id_branch_office", this.user.id_branch_office);
  data.append("id_user", this.user.id_user);
  data.append("id_credit", this.credit_amortization.id_credit);
  data.append("id_credit_detail", this.credit_amortization.id_credit_detail);
  data.append("id_financial_data", this.credit_amortization.id_financial_data);
  data.append("code", this.credit_amortization.code);
  data.append("date", this.credit_amortization.date);
  data.append("balance", this.credit_amortization.balance);
  data.append("payment_method", this.credit_amortization.payment_method);
  data.append("observation", this.credit_amortization.observation);
  data.append("annexes", this.credit_amortization.annexes);
  data.append("total", this.credit_amortization.total);
  data.append("credits_detail", JSON.stringify(this.credit_amortization.credits_detail));
  data.append("credits_details_agricultural", JSON.stringify(this.credit_amortization.credits_details_agricultural));

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.OpenVoucher(response.data.result.id_credit_amortization);
        me.$router.push({ name: "CreditAmortizationList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_credit = this.credit_amortization.id_credit.length == 0 ? true : false;
  this.errors.id_credit_detail = this.credit_amortization.id_credit_detail.length == 0 ? true : false;
  this.errors.payment_method = this.credit_amortization.payment_method.length == 0 ? true : false;
  this.errors.date = this.credit_amortization.date.length == 0 ? true : false;
  this.errors.code = this.credit_amortization.code.length == 0 ? true : false;
  this.errors.total = this.credit_amortization.total.length == 0 || parseFloat(this.credit_amortization.total) <= 0  ? true : false;
  this.errors.capital_diference = this.credit_amortization.capital_diference.length == 0 || parseFloat(this.credit_amortization.capital_diference) != 0  ? true : false;
  
  if (this.errors.id_credit) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_credit_detail) { this.validate = true; Swal.fire({ icon: 'warning', text: 'No cuenta con cuotas por pagar', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.capital_diference) { this.GetCreditDetailByAmortization(); this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique los pagos del capital', timer: 2000,}); return false;}else{ this.validate_add = false; }


  if (parseFloat(this.credit_amortization.total) > parseFloat(this.credit_amortization.balance)) {
    this.validate = true; 
    Swal.fire({ icon: 'warning', text: 'El monto de la amortización no debe superar al saldo capital', timer: 2000,}); 
    return false;
  }


  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar la amortización del crédito?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCreditAmortization();
    }
  });
}

function GetCreditByID() {
  let me = this;
  me.credit_amortization.id_credit = '';
  me.credit_amortization.id_credit_detail = '';
  me.credit_amortization.quota_number = '';
  me.credit_amortization.balance = '0.00';
  me.credit_amortization.interest = '';
  me.credit_amortization.quota = '0.00';
  me.credit_amortization.total = '0.00';
  me.credit_amortization.amortization = '0.00';
  me.credit_amortization.credits_detail = [];
  me.credit_amortization.credits_details = [];
  me.credit_amortization.credits_details_agricultural = [];
  me.credit_amortization.capital_diference = '0.00';
  if (this.client == null) {
    return false;
  }
  let url = me.url_base + "credit-amortization/credit-by-id";

  let data = {
    id_credit : this.client.value,
  }
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_amortization.id_credit = response.data.result.credit.id_credit;
        me.credit_amortization.id_product = response.data.result.credit.id_product;
        me.credit_amortization.quota_number = response.data.result.credit_detail.quota_number;
        me.credit_amortization.balance = response.data.result.credit_detail.balance;
        me.credit_amortization.interest = response.data.result.credit_detail.interest;
        me.credit_amortization.quota = '0.00';
        me.credit_amortization.id_credit_detail = response.data.result.credit_detail.id_credit_detail;
        me.credit_amortization.credits_details_agricultural = response.data.result.credit_detail_agricultural;
        me.credit_amortization.total = '0.00';
        
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    });
}


function OpenVoucher(id_credit_amortization) {
  let me = this;
  let url = me.url_base + "credit-amortization-sales-of-receip/"+ id_credit_amortization;
  var ventana1 = window.open(url, '_blank');
}

</script>
